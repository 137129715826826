<template>
    <div   class="cardrow  rounded-xl" style="width:50vh">
        <div class="card">
                <div>
                    <div class="numbers">{{valueNum}}</div>
                    <div class="cardName">{{title}}</div>
                </div>
                <div class="iconBx">
                    <v-icon v-text="icon" size="55" color="grey"></v-icon>
                </div>
            </div>
    </div>

</template>

<script>
export default {
    props: {
        title: String,
        valueNum: String,
        icon: String
    }

}
</script>

<style scoped>
.cardrow
{
    border-radius: 10px;
    margin: 20px 0px;

}
.card {
background: var(--white);
padding: 10px 20px;

border-radius: 10px;
display: flex;
justify-content: space-between;
cursor: pointer;
  border: #e2e2e2 solid 1px;

}

.card .numbers {


font-size: 1.5rem;
  color: var(--blue);
  font-weight: 500;

}

.card .cardName {
color: var(--black2);
  font-weight: 500;
font-size: 1.1rem;
margin-top: 5px;
}

.card .iconBx {
font-size: 3.5rem;
color: var(--black2);
}

.card:hover {
background: var(--blue);
}
.card:hover .numbers,
.card:hover .cardName,
.card:hover .iconBx {
color: var(--white);
}
</style>