


export default {


    exportExcel(jsonInput, filename) {

        let data = [];
            data = JSON.parse(JSON.stringify(jsonInput));
        import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({json2excel}) => {
            json2excel({
                data,
                name: filename,
            });
        }).catch((e) => {
            console.log(e)
        });


    },

    async importExcel(filePath) {
        const results = [];

        Papaparse.parse( this.file, {
            header: true,
            skipEmptyLines: true,
            complete: function( results ){
                this.content = results;
                this.parsed = true;
            }.bind(this)
        } );

    },


};