<template>


  <v-container >
   <VCard elevation="0" class="pa-2 ma-3" style="border: #dedddd solid 1px">
     <VCardText style="color: black; font-size: 1.7rem">
       تقارير SMS
     </VCardText>
     <v-row no-gutters>
       <v-col cols="12" md="4"

              xl="6"
       >
         <ReportAll />
       </v-col>

       <v-col cols="12" md="4"
              xl="6">
         <ReportDetail/>
       </v-col>

     </v-row>
   </VCard>
    <VCard elevation="0" class="pa-2 ma-3" style="border: #dedddd solid 1px">
      <VCardText style="color: black; font-size: 1.7rem">
        تقارير رسائل الواتساب
      </VCardText>

    </VCard>
    <VCard elevation="0" class="pa-2 ma-3" style="border: #dedddd solid 1px">
      <VCardText style="color: black; font-size: 1.7rem">
       تقارير الأشعارات
      </VCardText>

    </VCard>
  </v-container>
</template>

<script>


import ReportAll from '../components/dialogs/AllReportsDialogSms.vue';
import ReportDetail from '../components/dialogs/ReportsDetailsDialogSms.vue';
export default {

  components: {

    ReportAll,
    ReportDetail
  },
  data () {
    return {

    }
  },
  methods: {




  },
  async mounted() {

  }
}
</script>

<style scoped>


</style>