const getExtension = (filename) =>{

    const parts = filename.split('.');

    return parts[parts.length - 1];
}

const isImage = (filename) =>{

    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'jpeg':
            //etc
            return true;
    }
    return false;
}

const isVideo = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
        case 'wmv':
            return true;
    }
    return false;
}

const isDocument = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'pdf':
        case 'txt':
        case 'doc':
            return true;
    }
    return false;
}

const getQueryPath =(option)=>{

    let query =  Object.keys(option).map(key=>key+"="+(option[key]??""));
    return query.join("&");
}
const getBasicAuthorization = (username,password)=>{
    const auth = 'Basic ' + new Buffer(username + ':' + password).toString('base64');
    return auth;
}
export default {
    isVideo,
    isImage,
    getQueryPath,
    isDocument,
    getBasicAuthorization
}