<template>
  <div class="fontapp">

    <VCol md="12" >
      <v-toolbar elevation="0">


        <v-chip-group class="px-5 " >
          <v-chip class="chip"
                  v-for="(item, i) in dateFilter" :color="item.value? 'primary' : ''" @click="isloading?null:getChoose(i);" :key="i">{{item.title}}</v-chip>
        </v-chip-group>

        <VRow class="pt-7 px-5" v-if="isCustom">
          <v-flex xs12 sm2 md6>
            <v-dialog
                ref="dialog"
                v-model="menuFrom"

                persistent
                lazy
                full-width
                width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field

                    v-model="dateFrom"
                    label="من تاريخ"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom" scrollable>
                <v-spacer></v-spacer>
                <v-btn  color="primary" @click="menuFrom = false">الغاء</v-btn>
                <v-btn  color="primary" @click="getReport()">مواقع</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs6 sm4 md6>
            <v-dialog
                ref="dialog"
                v-model="menuTo"

                persistent
                lazy
                full-width
                width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field

                    v-model="dateTo"
                    label="الى تاريخ"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateTo" scrollable>
                <v-spacer></v-spacer>
                <v-btn  color="primary" @click="menuTo = false">الغاء</v-btn>
                <v-btn  color="primary" @click="getReport()">مواقع</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </VRow>
      </v-toolbar>

      <v-card class="mb-9 ml-5 mr-5 " >
        <VRow class="ml-4 mr-5 d-flex justify-space-between" align="center" justify="center">


        </VRow>
      </v-card>
      <v-card class="card ml-10 mr-10" elevation="0" >
        <v-card-title>


          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn  color="primary" @click="getExportExcel()" v-if="!isloading">استخراج Excel</v-btn>

        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :loading="isloading"
        ></v-data-table>
      </v-card>

    </VCol>
  </div>
</template>

<script>
import PushServicesSocket from '../services/PushServicesSocket.js';
import Loading from '../components/Loading.vue';
import ExportJsonExcel from "@/services/ExportJsonExcel";

export default {
  components: {
    Loading,
  },
  data () {
    return {
      isCustom: false,
      dateFilter:[
        {
          title:"اليوم حتى الان",
          value: true
        }  ,
        {
          title:"الأمس",
          value: false
        }  ,
        {
          title:"اخر 7 ايام",
          value: false
        }  ,
        {
          title:"اخر 30 يوماً",
          value: false
        } ,
        {
          title:"هاذا الشهر حتى الان",
          value: false
        } ,

        {
          title:"جميع الاوقات",
          value: false
        } ,
        {
          title:"تخصيص",
          value: false
        } ,
      ],
      isloading: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),

      menuFrom: false,
      menuTo: false,
      search: '',
      headers: [
        { text: 'المستخدم', value: 'USER_ID' },
        {
          text: 'الشركة',
          align: 'start',

          value: 'COMPANY_NO',
        },
        { text: 'اجمالي الرسائل', value: 'count' },



      ],
      desserts: [
      ],
    }
  },
  methods: {
    async getExportExcel(){
      this.isloading = true;

      await ExportJsonExcel.exportExcel(this.desserts,`${this.dateFrom} - ${this.dateTo}`);

      this.isloading = false;
    },
    getNameCampany(num){

      if(num=="77"||num=="1")
        return " يمن موبايل ";
      else  if(num=="73"||num=="0")
        return "يو - YOU";
      else  if(num=="71"||num=="2")
        return "سباء فون";
      else  if(num=="70"||num=="4")
        return "واي";
      else
        return "يمن موبايل";



    },
    async getChoose(value) {


      for(let i=0;i<this.dateFilter.length;i++)
        this.dateFilter[i].value = false;
      this.dateFilter[value].value = true;
      this.isCustom = value==this.dateFilter.length-1;
      let dateFrom =  new Date();
      let dateTo =  new Date();

      switch (value) {
        case 0:

          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)

          break;
        case 1:


          dateFrom.setDate(dateFrom.getDate()-1);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateFrom

          break;
        case 2:
          dateFrom.setDate(dateFrom.getDate()-7);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 3:
          dateFrom.setMonth(dateFrom.getMonth()-1);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 4:
          dateFrom.setDate(0);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 5:
          dateFrom.setFullYear(2017);
          dateTo = dateTo.toISOString().substr(0, 10)
          break;

      }



      if(!(value==this.dateFilter.length-1)){
        await this.getReportCustom(dateFrom,dateTo);
      }

    },
    async getReportCustom(dateFrom,dateTo) {
      this.isloading = true;
      let sum = 0;
      try {

        this.menuFrom = false;
        this.menuTo = false;
        this.desserts=[];
        const temp  = await PushServicesSocket.GetPostData("/report/getCount",{fromDate: dateFrom,toDateTime: dateTo});
        console.log(temp)
        if(temp.status){
          this.desserts = temp.data;
        }
        this.desserts.forEach((value, index) => {
          this.desserts[index].COMPANY_NO=this.getNameCampany(value.COMPANY_NO);
          sum+=this.desserts[index].count;
        });


      }catch (e){

      }
      this.desserts.push({
        count:sum,
        COMPANY_NO:"الاجمالي",
        USER_ID:"."
      });
      this.isloading = false;
    },
    async getReport() {

      let tempDate = new Date(this.dateTo);
      tempDate = tempDate.toISOString().substr(0, 10);
      this.menuFrom = false;
      this.menuTo = false;
      let tempFrom = new Date(this.dateFrom);
      tempFrom = tempFrom.toISOString().substr(0, 10);

      await this.getReportCustom(tempFrom,tempDate);


    },

  },

  async mounted() {
    await this.getChoose(0)

  }
}
</script>

<style scoped>

.chip {
  overflow: initial;
}
</style>
