<template>
  <div class="fontapp">

    <v-col md="12" >
      <v-toolbar elevation="0">


        <v-chip-group class="px-5">
          <v-chip class="chip" v-for="(item, i) in dateFilter" :color="item.value? 'primary' : ''" @click="isloading?null:getChoose(i);" :key="i">{{item.title}}</v-chip>
        </v-chip-group>

        <v-row class="pt-7 px-5" v-if="isCustom">
          <v-flex xs12 sm2 md6>
            <v-dialog
                ref="dialog"
                v-model="menuFrom"

                persistent
                lazy
                full-width
                width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field

                    v-model="dateFrom"
                    label="من تاريخ"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom" scrollable>
                <v-spacer></v-spacer>
                <v-btn  color="primary" @click="menuFrom = false">الغاء</v-btn>
                <v-btn  color="primary" @click="getReport()">مواقع</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs6 sm4 md6>
            <v-dialog
                ref="dialog"
                v-model="menuTo"

                persistent
                lazy
                full-width
                width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field

                    v-model="dateTo"
                    label="الى تاريخ"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateTo" scrollable>
                <v-spacer></v-spacer>
                <v-btn  color="primary" @click="menuTo = false">الغاء</v-btn>
                <v-btn  color="primary" @click="getReport()">مواقع</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </v-row>
      </v-toolbar>


      <v-card class="card ml-10 mr-10" elevation="0" >


        <v-data-table
            :loading="isloading"
            :headers="headers"
            :items="desserts"
            :items-per-page.sync="options.limit"
            :page.sync="options.page"
            :sort-by.sync="options.sortBy"
            :footer-props="footerOptions"
            :sort-desc.sync="options.sortDesc"
            :server-items-length="(options.full_count)"
            @update:page="fetchSmsHistory()"
            @update:pagination="fetchSmsHistory()"
            @update:items-per-page="fetchSmsHistory()"
            @update:sort-desc="fetchSmsHistory()"

        >

          <template v-slot:top>
            <v-row class="mt-2 ">

              <v-col cols="12" md="3"  sm="6"  class=" py-0 my-0 ">
                <v-text-field
                    outlined
                    dense
                    class="rounded-lg"
                    v-model="options.name"
                    @keydown.enter="fetchSmsHistory()"
                    label="بحث"
                    single-line
                    clearable
                ></v-text-field>
              </v-col>
              <v-btn @click="fetchSmsHistory"
                     color="primary"
                     rounded
                     dark
              >
                بحث
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="getExportExcel()"
                  v-if="!isloading && desserts.length>0">استخراج Excel</v-btn>
            </v-row>

          </template>


        </v-data-table>

      </v-card>

    </v-col>
  </div>
</template>

<script>
import PushServicesSocket from '../services/PushServicesSocket.js';
import Loading from '../components/Loading.vue';
import ExportJsonExcel from "@/services/ExportJsonExcel";
import utils from "@/services/util";

export default {
  components: {
    Loading,
  },
  data () {
    return {
      isCustom: false,



      dateFilter:[
        {
          title:"اليوم حتى الان",
          value: true
        }  ,
        {
          title:"الأمس",
          value: false
        }  ,
        {
          title:"اخر 7 ايام",
          value: false
        }  ,
        {
          title:"اخر 30 يوماً",
          value: false
        } ,
        {
          title:"هاذا الشهر حتى الان",
          value: false
        } ,

        {
          title:"جميع الاوقات",
          value: false
        } ,
        {
          title:"تخصيص",
          value: false
        } ,
      ],
      isloading: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      menuFrom: false,
      menuTo: false,
      search: '',
      headers: [
        { text: 'المستخدم', value: 'USER_ID' },
        {
          text: 'الرسالة',
          align: 'start',
          sortable: false,
          value: 'MESSAGE',
        },
        { text: 'رقم الهاتف', value: 'MOBILE_NO' },
        { text: 'تاريخ الارسال', value: 'SUBMIT_TIME' },
        { text: 'نقظة الارسال', value: 'SENDER' },

      ],
      options: {
        name:"",
        page:1,
        limit:10,
        sortBy:"",
        sortDesc:true,
        full_count:0,

      },
      footerOptions:
          {
            itemsPerPageOptions: [10,25, 50, 100],
          },
      desserts: [
      ],
    }
  },
  methods: {
    async getExportExcel(){
      const tempPage = this.options.page;
      const tempLimit = this.options.limit;
      const tempItem = this.desserts.slice();
      this.isloading = true;
      this.options.limit = 1000;
      let excelItmes =[];
      while (this.options.full_count > (excelItmes.length+1)){



        await this.fetchSmsHistory();
        if(this.desserts.length===0) break;
        excelItmes.push(...this.desserts);
        this.options.page +=1;

      }
      await ExportJsonExcel.exportExcel(excelItmes,`${this.dateFrom} - ${this.dateTo}`);

      this.options.page =tempPage;
      this.options.limit =tempLimit;
      this.desserts = tempItem;
      this.isloading = false;
    },
    async fetchSmsHistory () {
      await this.getReportCustom(this.dateFrom,this.dateTo)
    },
    async getChoose(value) {
      for(let i=0;i<this.dateFilter.length;i++)
        this.dateFilter[i].value = false;
      this.dateFilter[value].value = true;
      this.isCustom = value==this.dateFilter.length-1;
      let dateFrom =  new Date();
      let dateTo =  new Date();

      switch (value) {
        case 0:

          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)

          break;
        case 1:


          dateFrom.setDate(dateFrom.getDate()-1);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateFrom

          break;
        case 2:
          dateFrom.setDate(dateFrom.getDate()-7);

          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 3:
          dateFrom.setMonth(dateFrom.getMonth()-1);

          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 4:
          dateFrom.setDate(0);
          dateFrom = dateFrom.toISOString().substr(0, 10)
          dateTo = dateTo.toISOString().substr(0, 10)
          break;
        case 5:
          dateFrom.setFullYear(2017);
          dateTo = dateTo.toISOString().substr(0, 10)
          break;

      }
      if(!(value==this.dateFilter.length-1)){
        await this.getReportCustom(dateFrom,dateTo);
      }
    },
    async getReportCustom(dateFrom,dateTo) {

      try {
        this.isloading = true;
        this.menuFrom = false;
        this.menuTo = false;
        this.dateFrom =dateFrom;
        this.dateTo = dateTo;

        let _temp= await PushServicesSocket.GetData("/report/get?"+utils.getQueryPath({...this.options,fromDate: dateFrom,toDateTime: dateTo}));
        if(_temp.status){

          this.desserts = _temp.data;
          this.options.full_count = parseInt(_temp.count);
        }

      }catch (e) {
        console.log(e)
      }
      this.isloading = false;
    },
    async getReport() {

      this.isloading = true;
      this.menuFrom = false;
      let tempDate = new Date(this.dateTo);
      tempDate = tempDate.toISOString().substr(0, 10);
      let tempFrom = new Date(this.dateFrom);
      tempFrom = tempFrom.toISOString().substr(0, 10);

      this.menuTo = false;
      await this.getReportCustom(tempFrom,tempDate);
      this.isloading = false;
    },
    async nextPage (num) {
      this.desserts = [];
      this.getReportCustom(this.dateFrom,this.dateTo);
    },

  },

  async mounted() {
    await this.getChoose(0)

  }
}
</script>


<style scoped>

.chip {
  overflow: initial;
}
</style>
