<template>
  <div>

    <v-row justify="center">
      <v-dialog
          v-model="fullReportDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div  v-bind="attrs"
                v-on="on">
            <ReportCard title="الرسائل المرسلة حسب الفترة" icon="mdi-file-chart" value-num="تفصيلي"/>
          </div>

        </template>
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="fullReportDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>تقرير تفصيلي</v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>
          <Loading v-if="isloading"/>
          <ReportAll v-else />
        </v-card>
      </v-dialog>
    </v-row>
  </div>

</template>

<script>

import Loading from '@/components/Loading.vue';
import ReportAll from '@/components/ReportAllSms.vue';
import ReportCard from '@/components/ReportCard.vue';

export default {
  components: {
    Loading,
    ReportAll,
    ReportCard
  },
  data () {
    return {
      isloading: false,
      fullReportDialog: false,

    }
  },
  methods: {




  },
  async mounted() {

  }
}
</script>

<style scoped>


</style>